"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addStateListener = exports.persistentStart = exports.persistentEnd = exports.addStatsListener = exports.disconnect = exports.connect = exports.mapTrack = exports.mapStream = exports.addEvent = exports.enableDataCollection = exports.disableDataCollection = exports.addKeys = exports.setUserRating = exports.addTags = exports.setConfig = exports.init = void 0;
require("./types");
var sdk_1 = require("./sdk");
/**
 * Initialize SDK. Can be called multiple times but it will be initialized only at the first time.
 * Use 'setConfig' function after if you don't have rtcRoomId and rtcPeerId yet.
 * @param watchrtc
 * @param prefixesToWrap
 * @param services
 */
var init = function (watchrtcConfig, services) {
    (0, sdk_1.initSDK)((watchrtcConfig || {}), [""], services);
};
exports.init = init;
/**
 * You also can use watchRTC.setConfig function to set watchRTC configuration after calling init()
 * and before the creation of RTCPeerConnection objects
 */
var setConfig = function (watchrtcConfig) {
    (0, sdk_1.setConfig)(watchrtcConfig);
};
exports.setConfig = setConfig;
/**
 * @deprecated Tags no longer supported. Use addKeys instead
 * Add tags for peer session
 * @param rtcTags[]
 */
var addTags = function (
/** ["tag1", "tag2", "tag3"] */
_rtcTags) { };
exports.addTags = addTags;
/**
 * Set user rating and/or comment for peer session
 * @param rating number from 1 to 5
 * @param comment string
 */
var setUserRating = function (
/** 1 | 2 | 3 | 4 | 5 */
rating, comment) {
    (0, sdk_1.setUserRating)(rating, comment);
};
exports.setUserRating = setUserRating;
/**
 * Add keys for peer session
 * @param keys
 */
var addKeys = function (
/** { "key1": "value1", "key2": ["value2_1", "value2_2"]} */
keys) {
    (0, sdk_1.addKeys)(keys);
};
exports.addKeys = addKeys;
/**
 * Disables data collection
 */
var disableDataCollection = function () {
    (0, sdk_1.disableDataCollection)();
};
exports.disableDataCollection = disableDataCollection;
/**
 * Enables data collection
 */
var enableDataCollection = function () {
    (0, sdk_1.enableDataCollection)();
};
exports.enableDataCollection = enableDataCollection;
var addEvent = function (event) {
    (0, sdk_1.addEvent)(event);
};
exports.addEvent = addEvent;
/**
 * Map an incoming stream with a human-readable name
 * @param id The identifier of the stream (streamIdentifier attribute)
 * @param name The human-readable name
 * @deprecated
 */
var mapStream = function (id, name) {
    (0, sdk_1.mapStream)(id, name);
};
exports.mapStream = mapStream;
/**
 * Map an incoming track with a human-readable name
 * @param id The identifier of the track (trackIdentifier attribute)
 * @param name The human-readable name
 */
var mapTrack = function (id, name) {
    (0, sdk_1.mapTrack)(id, name);
};
exports.mapTrack = mapTrack;
var connect = function () {
    (0, sdk_1.connect)();
};
exports.connect = connect;
var disconnect = function () {
    (0, sdk_1.disconnect)();
};
exports.disconnect = disconnect;
var addStatsListener = function (listener) {
    (0, sdk_1.registerOnStatsListener)(listener);
};
exports.addStatsListener = addStatsListener;
var persistentEnd = function (nailUpCallEnd) {
    (0, sdk_1.persistentEnd)(nailUpCallEnd);
};
exports.persistentEnd = persistentEnd;
var persistentStart = function (roomId, peerId) {
    (0, sdk_1.persistentStart)(roomId, peerId);
};
exports.persistentStart = persistentStart;
var addStateListener = function (listener) {
    (0, sdk_1.registerStateListener)(listener);
};
exports.addStateListener = addStateListener;
exports.default = {
    /**
     * Initialize SDK. Can be called multiple times but it will be initialized only at the first time.
     * Use 'setConfig' function after if you don't have rtcRoomId and rtcPeerId yet.
     * @param watchrtc
     * @param prefixesToWrap
     */
    init: exports.init,
    /**
     * @deprecated Tags no longer supported. Use addKeys instead
     * Add tags for peer session
     * @param rtcTags[]
     */
    addTags: exports.addTags,
    /**
     * Set user rating and/or comment for peer session
     * @param rating number from 1 to 5
     * @param comment string
     */
    setUserRating: exports.setUserRating,
    /**
     * Add keys for peer session
     * @param keys
     */
    addKeys: exports.addKeys,
    /**
     * You also can use watchRTC.setConfig function to set watchRTC configuration after calling init()
     * and before the creation of RTCPeerConnection objects
     */
    setConfig: exports.setConfig,
    /**
     * Disables data collection
     */
    disableDataCollection: exports.disableDataCollection,
    /**
     * Enables data collection
     */
    enableDataCollection: exports.enableDataCollection,
    addEvent: exports.addEvent,
    /**
     * Map an incoming stream with a human-readable name
     * @param id The identifier of the stream (streamIdentifier attribute)
     * @param name The human-readable name
     * @deprecated
     */
    mapStream: exports.mapStream,
    /**
     * Map an incoming track with a human-readable name
     * @param id The identifier of the track (trackIdentifier attribute)
     * @param name The human-readable name
     */
    mapTrack: exports.mapTrack,
    connect: exports.connect,
    disconnect: exports.disconnect,
    addStatsListener: exports.addStatsListener,
    persistentEnd: exports.persistentEnd,
    persistentStart: exports.persistentStart,
    addStateListener: exports.addStateListener,
    qualityrtc: {
        run: sdk_1.qrtcRun,
        stop: sdk_1.qrtcStop,
    },
};
